"use client";

import { Box, Divider, Typography } from "components";
import "styles/components/bup-error.scss";

export default function NotFound() {
  return (
    <Box className="BupError-root" component="main">
      <Typography variant="h1">
        <b>404</b> <Divider orientation="vertical" variant="middle" /> Página no
        encontrada
      </Typography>
      <Typography variant="p">
        La página que estás buscando no existe o no está disponible.
      </Typography>
    </Box>
  );
}
